import React, { useContext } from 'react'
import { useStaticQuery, graphql } from "gatsby"

import { GlobalStateContext } from '../../utils/GlobalContextProvider';
import logoVitaFons from '../../../static/logoVitaFons.jpg'
import StyledPageServiceDeSoutien from './StyledPageServiceDeSoutien';

export const PageServiceDeSoutien = () => {
    const { pageServiceDeSoutien } = useStaticQuery(
        graphql`
            query {
                pageServiceDeSoutien:datoCmsPageServiceDeSoutien {
                    encart1Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart2Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart3Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    encart4Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                    banniere2Node {
                        childMarkdownRemark {
                          html
                        }
                    }
                  }
            }
          `)
    const { titlePage } = useContext(GlobalStateContext)
    return (
        <StyledPageServiceDeSoutien>
            <div className='container-page'>
                <div className='wrapper-titleh3'>
                    <h3>{titlePage}</h3>
                </div>
                <div className='container-img-txt'>
                    <img id='logo' alt='logo' src={logoVitaFons} />
                    <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageServiceDeSoutien.encart1Node.childMarkdownRemark.html}} />
                </div>
                
            </div>
            <div className='separator' dangerouslySetInnerHTML={{ __html: pageServiceDeSoutien.banniere2Node.childMarkdownRemark.html}} />
            <div className='container-page'>
                <div className='container-txt2' dangerouslySetInnerHTML={{ __html: pageServiceDeSoutien.encart2Node.childMarkdownRemark.html}} />
                <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageServiceDeSoutien.encart3Node.childMarkdownRemark.html}} />
                <div className='little-separator' />
                <div className='container-txt1' dangerouslySetInnerHTML={{ __html: pageServiceDeSoutien.encart4Node.childMarkdownRemark.html}} />
            </div>
        </StyledPageServiceDeSoutien>
    )
}

import styled from 'styled-components'

const StyledPageServiceDeSoutien = styled.section`
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

        .container-page {
            max-width: 1384px;
            padding: 0 24px;
        }

        h3 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
            padding: 24px 0 48px 0;
        }
        h4 {
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 21px;
            line-height: 25px;
            color: #000000;
            padding: 24px 0;
        }
        small {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 29px;
            text-transform: capitalize;
            padding: 0 4px;
        }
        .container-txt1 {
            width: 100%;
            height: auto;
            margin-bottom: 24px;
            position: relative;
        }

        #logo {
            padding-left: 24px;
            float: right;
            align-self: flex-end;
            height: 100px;
        }

        .little-separator {
            width: 261px;
            height: 6px;
            background: #4F324F;
            margin: 48px 0;
        }

        ol {
            list-style: decimal;
            list-style-position: inside;
        }

        em {
            display: initial;
            font-size: 14px;
        }

        p, li {
            padding: 12px 0;
        }
        li::marker {
            padding-left: 24px;
        }

        .container-txt2 {
            width: 100%;
            background: #F4E1F4;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
            padding: 24px;
            margin-bottom: 48px;
        }

        .separator {
            width: 100vw;
            height: auto;
            background: linear-gradient(180deg, #8F6A8F 0%, #4E1D4E 100%);
            display: flex;
            padding: 24px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
        }
        .separator p {
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 26px;
            text-align: left;
            color: #FFFFFF;
            width: 97%;
            margin-bottom: 12px;
        }
        .separator p strong {
            font-style: italic;
        }

        .separator .btnToHome {
            background: linear-gradient(180deg, #F4E1F4 0%, #B08DB0 100%);
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #FFFDF9;
            padding: 8px 24px;
            cursor: pointer;
            transition: 400ms;
            text-decoration: none;
        }

        .separator .btnToHome:hover {
            transform: scale(1.06);
            transition: 400ms;
        }

        @media screen and (max-width: 700px) {
            h3 {
                font-size: 22px !important;
            }
            small {
                font-size: 19px !important;
            }
            #img {
                width: 95%;
            }
            .separator p {
                width: 95%;
                font-size: 21px;
                line-height: 26px;
            }
        }
`

export default StyledPageServiceDeSoutien